
import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";
import { IVisitService } from "@/services";

import { VisitInfos } from "prometheus-synced-ui";

import { VisitAutocomplete } from "prometheus-synced-ui";

@Component({
  components: {
    VisitAutocomplete,
  },
})
export default class VisitAutocompleteWrapper extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  nuclearUnitId!: string | null;

  get visitService() {
    return this.container.resolve<IVisitService>(S.VISIT_SERVICE);
  }

  loading: boolean = false;
  visits: VisitInfos[] = [];

  mounted() {
    this.shouldFetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.visits = await this.visitService.getMany({
        nuclearUnitId: this.nuclearUnitId,
      });
    } finally {
      this.loading = false;
    }
  }

  shouldFetch() {
    if (this.nuclearUnitId) {
      this.fetch();
    }
  }

  @Watch("nuclearUnitId")
  onPropsChange = this.shouldFetch;
}
