
import { Component, Prop, Vue } from "vue-property-decorator";
import { lightFormat } from "date-fns";

import { EMPTY } from "@/config/literals";

import { OffCriteriaTableResume } from "@/domain";

import VisitSelection from "@/components/Core/ExamReports/Transactions/VisitSelection.vue";
import TableDialog from "@/components/Core/ExamReports/Transactions/Childs/TableDialog.vue";
import OffCriteriaDetail from "@/components/Core/ExamReports/Transactions/Childs/OffCriteriaDetail.vue";
import ZoneDisplay from "@/components/Core/ExamReports/Transactions/Childs/ZoneDisplay.vue";

@Component({
  components: { VisitSelection, TableDialog, OffCriteriaDetail, ZoneDisplay },
  filters: {
    prettyDate(date: Date | null): string {
      return date != null ? lightFormat(date, "dd/MM/yyyy") : EMPTY;
    },
  },
})
export default class OffCriteriaTable extends Vue {
  @Prop({ required: true })
  tables!: OffCriteriaTableResume[];

  @Prop({ required: true })
  loading!: boolean;

  get headers() {
    return [
      {
        text: "Zone",
        align: "center",
        sortable: true,
        value: "zone",
      },
      {
        text: "Version",
        align: "center",
        sortable: true,
        value: "runVersion",
      },
      {
        text: "Contrôle",
        align: "center",
        sortable: true,
        value: "lastUpdate",
      },
      {
        text: "Taille",
        align: "center",
        sortable: true,
        value: "dimensions",
      },
      {
        text: "Nombre Mesures",
        align: "center",
        sortable: true,
        value: "measureNumber",
      },
      {
        text: "Hors-Critères",
        align: "center",
        sortable: true,
        value: "offCriteriaNumber",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ];
  }

  previewDialog: boolean = false;
  tableId: string | null = null;

  preview(id: string) {
    this.previewDialog = true;
    this.tableId = id;
  }
}
