
import { Component, Inject, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { OffCriteriaTableResume } from "@/domain";

import VisitSelection from "@/components/Core/ExamReports/Transactions/VisitSelection.vue";
import OffCriteriaTable from "@/components/Core/ExamReports/Transactions/OffCriteriaTable.vue";
import Loading from "@/views/Loading.vue";

import { IMeasureTableService } from "@/services";

@Component({
  components: {
    VisitSelection,
    OffCriteriaTable,
    Loading
  },
})
export default class GlobalOffCriteria extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get measureTableService() {
    return this.container.resolve<IMeasureTableService>(
      S.MEASURE_TABLE_SERVICE
    );
  }

  showTable: boolean = false;
  tables: OffCriteriaTableResume[] = [];
  loading: boolean = false;
  powerPlantId: string | null = null;
  nuclearUnitId: string | null = null;
  visitId: string | null = null;

  async fetch() {
    try {
      this.loading = true;
      this.tables = await this.measureTableService.getOffCriteria({
        powerPlantId: this.powerPlantId,
        nuclearUnitId: this.nuclearUnitId,
        visitId: this.visitId,
      });
      this.showTable = true;
    } finally {
      this.loading = false;
    }
  }
}
