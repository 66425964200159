
import { Component, Prop, Vue } from "vue-property-decorator";

import { OffCriteriaTableResume } from "@/domain";

@Component
export default class OffCriteriaDetail extends Vue {
  @Prop({ required: true })
  item!: OffCriteriaTableResume;

  getNumberClass(number: number): string {
    return number > 0 ? `font-weight-bold orange--text` : "";
  }
}
