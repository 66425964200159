
import { Component, Prop, Vue } from "vue-property-decorator";

import { OffCriteriaTableResume } from "@/domain";

@Component
export default class ZoneDisplay extends Vue {
  @Prop({ required: true })
  item!: OffCriteriaTableResume;
}
