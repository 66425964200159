
import { Component, Prop, Vue } from "vue-property-decorator";

import PowerPlantAutocomplete from "@/components/General/Autocompletes/Localisation/PowerPlantAutocompleteWrapper.vue";
import NuclearUnitAutocomplete from "@/components/General/Autocompletes/Localisation/DependentNuclearUnitAutocomplete.vue";
import VisitAutocompleteWrapper from "@/components/General/Autocompletes/ExamReports/VisitAutocompleteWrapper.vue";

@Component({
  components: {
    PowerPlantAutocomplete,
    NuclearUnitAutocomplete,
    VisitAutocompleteWrapper,
  },
})
export default class VisitSelection extends Vue {
  @Prop({ required: true })
  powerPlantId!: string | null;

  @Prop({ required: true })
  nuclearUnitId!: string | null;

  @Prop({ required: true })
  visitId!: string | null;

  updatePowerPlant(id: string | null) {
    this.$emit("update:visit-id", null);
    this.$emit("update:nuclear-unit-id", null);
    this.$emit("update:power-plant-id", id);
  }

  updateNuclearUnit(id: string | null) {
    this.$emit("update:visit-id", null);
    this.$emit("update:nuclear-unit-id", id);
  }

  updateVisit(id: string | null) {
    this.$emit("update:visit-id", id);    
  }

  reset() {
    this.$emit("update:visit-id", null);
    this.$emit("update:nuclear-unit-id", null);
    this.$emit("update:power-plant-id", null);
  }
}
