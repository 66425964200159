
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";
import { IMeasureTableService } from "@/services";

import {
  FilterTypes,
  MeasureTableDetails,
  UiMeasure,
} from "prometheus-synced-ui";

import { TableDisplay } from "prometheus-synced-ui";
import Loading from "@/views/Loading.vue";
import FilterInputs from "@/components/General/Table/FilterInputs.vue";

@Component({ components: { TableDisplay, Loading, FilterInputs } })
export default class TableDialog extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  tableId!: string;

  get measureTableService() {
    return this.container.resolve<IMeasureTableService>(
      S.MEASURE_TABLE_SERVICE
    );
  }

  loading: boolean = false;
  table: MeasureTableDetails | null = null;
  measures: UiMeasure[] = [];
  filter: FilterTypes = FilterTypes.none;
  showOffCriteria: boolean = true;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.table = await this.measureTableService.get(this.tableId);
      this.measures = this.table.getUiMeasures();
    } finally {
      this.loading = false;
    }
  }
}
